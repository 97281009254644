import { computed, type ComputedRef } from 'vue'

import type { Settings, SettingsAuthentication } from '@/models/Setting'
import { useSettingsStore } from '@/stores/settings'

export function getTypesFromSettingsStore(): ComputedRef<{
  types?: string[]
}> {
  const settings = useSettingsStore()
  const computedSettings = computed((): Partial<Settings> => settings.settings)
  const settingsAuth = computed((): SettingsAuthentication | undefined => computedSettings.value?.auth)

  const computedTypes = computed((): string[] => settingsAuth.value?.types || [])

  /**
   * push usage to top
   * remove duplicate
   */
  return computed(() => ({
    types: computedTypes.value,
  }))
}
