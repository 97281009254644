<script setup lang="ts">
// TODO: This file has been copied from vue-ui package and should be removed
// Portal is still using an old version, after it's updated this file should be removed and the one from package should be used.

import { ASelectInput, type SelectInputOption } from '@atabix/vue-ui'
import type { HTMLAttributes } from 'vue'
import { computed } from 'vue'

export interface LanguageItem {
  code: string
  name: string
}

export interface LanguageInputProperties {
  items: LanguageItem[]
  class?: HTMLAttributes['class']
  ariaLabel?: string
}

const properties = withDefaults(defineProps<LanguageInputProperties>(), {
  class: undefined,
  ariaLabel: undefined,
})

const value = defineModel<string | undefined>({ required: true })

interface LanguageInputSlots {
  flag: (code: string) => void
}
defineSlots<LanguageInputSlots>()

const computedItems = computed((): SelectInputOption[] => {
  return properties.items.map((item) => ({
    ...item,
    value: item.code,
  }))
})
</script>

<template>
  <ASelectInput v-model="value" :aria-label="properties.ariaLabel" :class="properties.class" :items="computedItems">
    <template #item="item">
      <div class="flex gap-1">
        <slot name="flag" v-bind="item.item.value" />
        <p>{{ item.item.name }}</p>
      </div>
    </template>
  </ASelectInput>
</template>
