<script setup lang="ts">
import { AButton, ADivider, ALogo, AMenu, type ListItemInterface, type Testable } from '@atabix/vue-ui'
import { createReusableTemplate, useDark, useToggle } from '@vueuse/core'
import { AccountOutlineIcon, LogoutIcon, MenuIcon, WindowCloseIcon } from 'mdi-vue3'
import { markRaw, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useAuthDriver } from '@/auth'
import AlertBanner from '@/components/AlertBanner.vue'
import ContainerWrapper from '@/components/ContainerWrapper.vue'
import { RouteName } from '@/enums'
import { useAuthStore } from '@/stores/auth'
import { useTasksStore } from '@/stores/tasks'

interface PageHeaderProperties extends Testable {
  title: string
  logo?: string
  items?: ListItemInterface[]
}

const { t } = useI18n({
  messages: {
    en: {
      logout: 'Logout',
      '{item} outstanding tasks':
        'You currently have {item} case(s) which require your attention. | You currently have {item} case which requires your attention. | You currently have {item} cases which require your attention.',
    },
    nl: {
      logout: 'Uitloggen',
      '{item} outstanding tasks':
        'Er zijn {item} zaken met openstaande taken. | Er is {item} zaak met openstaande taken. | Er zijn {item} zaken met openstaande taken.',
    },
    de: {
      logout: 'Ausloggen',
      '{item} outstanding tasks':
        'Sie haben derzeit {item} Fälle mit ausstehenden Aufgaben. | Sie haben derzeit {item} Fall mit ausstehenden Aufgaben. | Sie haben derzeit {item} Fälle mit ausstehenden Aufgaben.',
    },
  },
})

const auth = useAuthStore()
const tasks = useTasksStore()
const [isMenuOpen, toggleMenu] = useToggle(false)
const isDark = useDark()

const [DefineLogo, ReuseLogo] = createReusableTemplate()
const [DefineActions, ReuseActions] = createReusableTemplate()

withDefaults(defineProps<PageHeaderProperties>(), {
  logo: undefined,
  items: () => [],
})

const route = useRoute()

watch(
  route,
  () => {
    toggleMenu(false)
  },
  { flush: 'pre', immediate: true, deep: true },
)

onMounted(initialize)

async function initialize() {
  await tasks.refresh()
}
</script>

<template>
  <DefineLogo>
    <div class="flex items-center justify-center">
      <img v-if="logo" :alt="title" class="h-9 select-none" :src="logo" />
      <ALogo v-else :dark="isDark" :size="36" />
    </div>
  </DefineLogo>

  <DefineActions>
    <div class="flex items-center justify-between gap-4">
      <span class="flex flex-row items-center justify-center gap-2 text-nowrap text-base">
        <AccountOutlineIcon class="h-5" />
        {{ auth.user?.name }}
      </span>
      <div class="flex flex-row gap-4">
        <AButton
          :click="() => useAuthDriver().logout()"
          data-test="button:logout"
          :icon="markRaw(LogoutIcon)"
          :title="t('logout')"
          variant="outline"
        />
      </div>
    </div>
  </DefineActions>

  <div class="w-full min-w-[100vw] bg-background pr-2" :data-test>
    <ContainerWrapper class="relative flex gap-6 py-6">
      <ReuseLogo />
      <div class="hidden items-end justify-between md:flex">
        <span class="block text-2xl font-bold">{{ title }}</span>
        <ReuseActions />
      </div>
      <div
        class="absolute inset-y-0 right-0 flex cursor-pointer select-none flex-row items-center gap-1 px-4 md:hidden"
        role="button"
        tabindex="0"
        @click="() => toggleMenu()"
        @keydown.enter.stop.prevent="() => toggleMenu()"
        @keydown.space.stop.prevent="() => toggleMenu()"
      >
        <span class="block">Menu</span>
        <MenuIcon class="size-5 text-foreground-accent" />
      </div>
    </ContainerWrapper>

    <Transition>
      <div v-show="isMenuOpen" class="absolute inset-0 z-20 flex flex-col gap-6 bg-background-tint p-6 transition-all md:hidden">
        <ReuseLogo />
        <div class="absolute right-0 top-0 flex cursor-pointer flex-row items-center gap-1 px-4 py-6 md:hidden">
          <WindowCloseIcon class="block size-6 cursor-pointer text-foreground-subtle" @click="() => toggleMenu()" />
        </div>

        <AMenu class="w-full" :items="items" :title="title" />
        <ADivider />
        <ReuseActions />
      </div>
    </Transition>
  </div>

  <div v-if="tasks.matters.length > 0">
    <AlertBanner
      :action="{ title: t('view {item}', { item: t('matter', 0) }), to: { name: RouteName.MATTERS_INCOMPLETE } }"
      :title="t('{item} outstanding tasks', { item: tasks.matters.length }, tasks.matters.length)"
    />
  </div>
</template>
