/* eslint-disable security/detect-object-injection */
import type {
  ClientBaseUrlResolver,
  ClientErrorResolver,
  ClientHeaderResolver,
  ClientOptions,
  ClientResponseResolver,
  ModelSortOptions,
  ModelSortOrder,
  RequestOptions,
} from '@atabix/core'
import { Client, Model as ModelClient, StatelessFileClient } from '@atabix/core'
import type { AxiosError, AxiosResponse } from 'axios'

import { useAuthDriver } from '@/auth'
import TokenDriver from '@/auth/TokenDriver'
import { LocaleService } from '@/helpers/LocaleService'
import type { ModelMeta } from '@/models'
import { useSettingsStore } from '@/stores/settings'
import { useTokenStore } from '@/stores/token'

// Enable for debug information from the API calls.
Client.isDebug = false // TNS_ENV !== 'production';

const applicationId = 'nl.atabase.portal'

export const baseHeaderResolver: ClientHeaderResolver = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': applicationId,
  'X-App-Platform': 'web',
  'accept-language': LocaleService.getLocale(),
})

export const authorizedHeaderResolver: ClientHeaderResolver = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': applicationId,
  'X-App-Platform': 'web',
  Authorization: useTokenStore().access ? `Bearer ${useTokenStore().access}` : '',
  'accept-language': LocaleService.getLocale(),
})

export const formsAuthorizedHeaderResolver: ClientHeaderResolver = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': applicationId,
  'X-App-Platform': 'web',
  Authorization: useTokenStore().access ? `Entity ${useTokenStore().access}` : '',
  'accept-language': LocaleService.getLocale(),
})

export const environmentBaseUrlResolverFactory = (): ClientBaseUrlResolver => {
  return (): string => {
    return import.meta.env.VITE_APP_HOST_API ?? 'https://api.accounts.atabase.nl'
  }
}

export const crmBaseUrlResolver: ClientBaseUrlResolver = (): string => {
  return useSettingsStore().get('api.crm') || ''
}

export const formsBaseUrlResolver: ClientBaseUrlResolver = (): string => {
  return useSettingsStore().get('api.forms') || ''
}

export const responseResolver: ClientResponseResolver = (response: AxiosResponse): Promise<object> => Promise.resolve(response.data)

// const useHttp404LoopPreventionStore = defineStore('useHttp404LoopPreventionStore', () => {
//   const timestamp = ref<number>(0)
//   const count = ref<number>(0)
//   return { timestamp, count }
// })

export const errorResolver: ClientErrorResolver = async (error, client, request): Promise<AxiosError> => {
  const logPrefix = '[NETWORK-RESPONSE]'

  if (!error.response) {
    throw error
  }

  if (error?.response?.status) {
    Client.debug(`${logPrefix} HTTP ${error?.response.status}`)

    const statusText = error.response.statusText
    const data: { errors: Record<string, string>; message: string } = {
      errors: {},
      message: '',
      ...(typeof error.response.data === 'object' ? error.response.data : {}),
    }

    switch (error?.response?.status) {
      case 401: {
        console.error({ title: statusText, text: data.message })

        const token = useTokenStore()

        if (token.refresh) {
          await TokenDriver.refresh(120)

          if (token.access) {
            // try {
            //   const loopPreventionStore = useHttp404LoopPreventionStore()
            //   const newTimestamp = Math.ceil(Date.now() / 1000)
            //   if (loopPreventionStore.timestamp !== newTimestamp) {
            //     loopPreventionStore.timestamp = newTimestamp
            //     loopPreventionStore.count = 0
            //   }
            //   loopPreventionStore.count += 1
            //   if (loopPreventionStore.count > 10) {
            //     console.error('[http] Loop detected, 401 occurred 10 times within 1 second')
            //     throw new Error('[http] Loop detected, 401 occurred 10 times within 1 second')
            //   }

            const originalRequestResponse = await client.withoutErrorResolver().call(request.method, request.url, request.payload, request.headers)

            if (originalRequestResponse) {
              return originalRequestResponse
            }
            // } catch (error) {
            //   console.error('[401] With afterwards probably a 500', error)
            // }
          }
        }

        await useAuthDriver().logout()

        throw error
      }
      case 403: {
        console.error({ title: statusText, text: data.message })
        break
      }
      case 404: {
        console.error({
          title: 'Page / Resource not found',
          text: data.message,
        })
        break
      }

      case 422: {
        const errors: string[] = []

        for (let index = 0; index < Object.keys(data.errors).length; index++) {
          const name: string = Object.keys(data.errors)[index]
          errors.push(data.errors[name])
        }

        const message = errors.join('<br />')

        console.error({ title: statusText, text: message })

        throw error
      }

      default: {
        console.error({
          title: 'Fatal error',
          text: 'Please contact the helpdesk for this problem.',
        })
        throw error
      }
    }
  }

  if (error) {
    Client.debug(`${logPrefix} ${JSON.stringify(error)} ${error}`)
  }

  throw error
}

export class Model<
  TFilters extends Record<string, unknown>,
  TCreatePayload extends Record<string, unknown>,
  TUpdatePayload extends Record<string, unknown>,
  TPutPayload extends Record<string, unknown>,
  TIncludes,
  TMetaIncludes,
  TSorts,
> extends ModelClient {
  protected $primaryKey: keyof this = 'id' as keyof this
  protected $fillable: (keyof this)[] = []

  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: authorizedHeaderResolver,
    baseUrlResolver: environmentBaseUrlResolverFactory(),
    errorResolver,
    responseResolver,
  }

  public tenant(id: string): this {
    return this.setHeader('X-Tenant-ID', id)
  }

  public setHeader(key: string, value: string): this {
    this.$options.headers[key] = value

    return this
  }

  public create(attributes?: TCreatePayload): Promise<this> {
    return super.create.call(this, attributes)
  }
  public put(attributes?: TPutPayload): Promise<this> {
    return super.put.call(this, attributes)
  }
  public update(attributes?: TUpdatePayload): Promise<this> {
    return super.update.call(this, attributes)
  }
  public include(includes: TIncludes | TIncludes[]): this {
    return super.include.call(this, includes)
  }
  public filter<TFilterKey extends keyof TFilters>(filters: TFilterKey | TFilters, value?: TFilters[TFilterKey]): this {
    return super.filter.call(this, filters, value)
  }
  public removeFilter<TFilterKey extends keyof TFilters>(key: TFilterKey): this {
    return super.removeFilter.call(this, key)
  }
  public reject<TFilterKey extends keyof TFilters>(rejects: TFilterKey | TFilters, value?: TFilters[TFilterKey]): this {
    return super.reject.call(this, rejects, value)
  }
  public removeReject<TFilterKey extends keyof TFilters>(key: TFilterKey): this {
    return super.removeReject.call(this, key)
  }
  public includeMeta(includes: TMetaIncludes | TMetaIncludes[]): this {
    return super.includeMeta.call(this, includes)
  }
  public sort(key: TSorts | null, order: ModelSortOrder = 'ASC'): this {
    return super.sort.call(this, key as string, order)
  }
  public getSortOptions(): ModelSortOptions<TSorts> {
    return super.getSortOptions.call(this)
  }
}

export class Crm<
  TFilters extends Record<string, unknown>,
  TCreatePayload extends Record<string, unknown>,
  TUpdatePayload extends Record<string, unknown>,
  TPutPayload extends Record<string, unknown>,
  TIncludes,
  TMetaIncludes,
  TSorts,
> extends ModelClient {
  protected $primaryKey: keyof this = 'id' as keyof this
  protected $fillable: (keyof this)[] = []

  public meta!: ModelMeta

  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: authorizedHeaderResolver,
    baseUrlResolver: crmBaseUrlResolver,
    errorResolver,
    responseResolver,
  }

  public tenant(id: string): this {
    return this.setHeader('X-Tenant-ID', id)
  }

  public setHeader(key: string, value: string): this {
    this.$options.headers[key] = value

    return this
  }

  public create(attributes?: TCreatePayload): Promise<this> {
    return super.create.call(this, attributes)
  }
  public put(attributes?: TPutPayload): Promise<this> {
    return super.put.call(this, attributes)
  }
  public update(attributes?: TUpdatePayload): Promise<this> {
    return super.update.call(this, attributes)
  }
  public include(includes: TIncludes | TIncludes[]): this {
    return super.include.call(this, includes)
  }
  public filter<TFilterKey extends keyof TFilters>(filters: TFilterKey | TFilters, value?: TFilters[TFilterKey]): this {
    return super.filter.call(this, filters, value)
  }
  public removeFilter<TFilterKey extends keyof TFilters>(key: TFilterKey): this {
    return super.removeFilter.call(this, key)
  }
  public reject<TFilterKey extends keyof TFilters>(rejects: TFilterKey | TFilters, value?: TFilters[TFilterKey]): this {
    return super.reject.call(this, rejects, value)
  }
  public removeReject<TFilterKey extends keyof TFilters>(key: TFilterKey): this {
    return super.removeReject.call(this, key)
  }
  public includeMeta(includes: TMetaIncludes | TMetaIncludes[]): this {
    return super.includeMeta.call(this, includes)
  }
  public sort(key: TSorts | null, order: ModelSortOrder = 'ASC'): this {
    return super.sort.call(this, key as string, order)
  }
  public getSortOptions(): ModelSortOptions<TSorts> {
    return super.getSortOptions.call(this)
  }
}

export class Forms<
  TFilters extends Record<string, unknown>,
  TCreatePayload extends Record<string, unknown>,
  TUpdatePayload extends Record<string, unknown>,
  TPutPayload extends Record<string, unknown>,
  TIncludes,
  TMetaIncludes,
  TSorts,
> extends ModelClient {
  protected $primaryKey: keyof this = 'id' as keyof this
  protected $fillable: (keyof this)[] = []

  public meta!: ModelMeta

  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: formsAuthorizedHeaderResolver,
    baseUrlResolver: formsBaseUrlResolver,
    errorResolver,
    responseResolver,
  }

  public tenant(id: string): this {
    return this.setHeader('X-Tenant-ID', id)
  }

  public setHeader(key: string, value: string): this {
    this.$options.headers[key] = value

    return this
  }

  public create(attributes?: TCreatePayload): Promise<this> {
    return super.create.call(this, attributes)
  }
  public put(attributes?: TPutPayload): Promise<this> {
    return super.put.call(this, attributes)
  }
  public update(attributes?: TUpdatePayload): Promise<this> {
    return super.update.call(this, attributes)
  }
  public include(includes: TIncludes | TIncludes[]): this {
    return super.include.call(this, includes)
  }
  public filter<TFilterKey extends keyof TFilters>(filters: TFilterKey | TFilters, value?: TFilters[TFilterKey]): this {
    return super.filter.call(this, filters, value)
  }
  public removeFilter<TFilterKey extends keyof TFilters>(key: TFilterKey): this {
    return super.removeFilter.call(this, key)
  }
  public reject<TFilterKey extends keyof TFilters>(rejects: TFilterKey | TFilters, value?: TFilters[TFilterKey]): this {
    return super.reject.call(this, rejects, value)
  }
  public removeReject<TFilterKey extends keyof TFilters>(key: TFilterKey): this {
    return super.removeReject.call(this, key)
  }
  public includeMeta(includes: TMetaIncludes | TMetaIncludes[]): this {
    return super.includeMeta.call(this, includes)
  }
  public sort(key: TSorts | null, order: ModelSortOrder = 'ASC'): this {
    return super.sort.call(this, key as string, order)
  }
  public getSortOptions(): ModelSortOptions<TSorts> {
    return super.getSortOptions.call(this)
  }
}

class RpcClient extends Client {
  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: authorizedHeaderResolver,
    baseUrlResolver: environmentBaseUrlResolverFactory(),
    errorResolver,
    responseResolver,
  }

  execute(signature: string, payload: object, options: RequestOptions = {}) {
    return this.call(
      'POST',
      '',
      {
        signature,
        body: payload,
      },
      {},
      options,
    )
  }
}
const rpcClient = new RpcClient()

class CrmRpcClient extends Client {
  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: authorizedHeaderResolver,
    baseUrlResolver: () => {
      const url = useSettingsStore().get('api.crm') || ''

      return url ? `${url}/rpc/public` : ''
    },
    errorResolver,
    responseResolver,
  }

  execute(signature: string, payload: object, options: RequestOptions = {}) {
    return this.call(
      'POST',
      '',
      {
        signature,
        body: payload,
      },
      {},
      options,
    )
  }
}
const crmRpcClient = new CrmRpcClient()
const statelessFileClient = new StatelessFileClient({ rpcClient: crmRpcClient })

class BaseUrlClient extends Client {
  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: baseHeaderResolver,
    baseUrlResolver: environmentBaseUrlResolverFactory(),
    errorResolver,
    responseResolver,
  }

  execute(signature: string, payload: object, options: RequestOptions = {}) {
    return this.call(
      'POST',
      '',
      {
        signature,
        body: payload,
      },
      {},
      options,
    )
  }
}
const baseUrlClient = new BaseUrlClient()

class PasswordClient extends Client {
  protected $options: ClientOptions = {
    baseUrl: 'v1/password',
    headers: {},
    headerResolver: authorizedHeaderResolver,
    baseUrlResolver: environmentBaseUrlResolverFactory(),
    errorResolver,
    responseResolver,
  }

  forgot(payload: object, options: RequestOptions = {}) {
    return this.call('POST', `${this.baseUrl}/forgot`, payload, {}, options)
  }

  reset(payload: object, options: RequestOptions = {}) {
    return this.call('POST', `${this.baseUrl}/reset`, payload, {}, options)
  }
}
const passwordClient = new PasswordClient()

class HttpClient extends Client {
  protected $options: ClientOptions = {
    baseUrl: '',
    headers: {},
    headerResolver: authorizedHeaderResolver,
    baseUrlResolver: environmentBaseUrlResolverFactory(),
    errorResolver,
    responseResolver,
  }
}
const httpClient = new HttpClient()

export { baseUrlClient, httpClient, passwordClient, rpcClient, statelessFileClient }

export class FetchError extends Error {
  constructor(
    public statusText: string,
    public status: number,
    public data: unknown,
  ) {
    super(statusText)
  }
}
