import type { SettingCdn } from '@/models/Setting'
import { useSettingsStore } from '@/stores'

export type CdnEnvironment = 'dev' | 'test' | 'acceptance' | ''

export function buildCdnLinkElements(packageName: string) {
  const cdnEnvironment = getEnvironment(useSettingsStore().get('cdn')?.environment)
  const fullCdnUrl = `https://cdn.${cdnEnvironment ? `${cdnEnvironment}.` : ''}atabase.nl/`
  const cdnLink = getCdnLink(fullCdnUrl, packageName)
  return {
    css: getCssLink(cdnLink),
    js: getJsLink(cdnLink),
  }
}

function getEnvironment(environment: SettingCdn['environment'] | undefined): CdnEnvironment {
  switch (environment) {
    case 'development': {
      return 'dev'
    }
    case 'test': {
      return 'test'
    }
    case 'acceptance': {
      return 'acceptance'
    }
    default: {
      return ''
    }
  }
}

function getCdnLink(cdnUrl: string, packageName: string) {
  return `${cdnUrl}${packageName}`
}

function getCssLink(link: string) {
  return `${link}/embed-latest.css`
}

function getJsLink(link: string) {
  return `${link}/embed-latest.js`
}
