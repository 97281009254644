/* eslint-disable unicorn/consistent-function-scoping */
export default function getHeadElementManager() {
  function appendChildToHead(element: HTMLElement) {
    document.head.append(element)
  }

  function createScriptElement(js: string, id: string): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
      const jsElement = document.querySelector(`#${id}`)
      if (jsElement) {
        resolve(jsElement as HTMLScriptElement)
        return
      }
      const script = document.createElement('script')
      script.src = js
      script.id = id
      script.addEventListener('load', resolve(script))
      script.addEventListener('error', () => reject(new Error(`Failed to load script: ${js}`)))
      appendChildToHead(script)
    })
  }

  function createLinkElement(css: string, id: string): Promise<HTMLLinkElement> {
    return new Promise((resolve, reject) => {
      const cssElement = document.querySelector(`#${id}`)
      if (cssElement) {
        resolve(cssElement as HTMLLinkElement)
        return
      }
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.id = id
      link.href = css
      link.addEventListener('load', resolve(link))
      link.addEventListener('error', () => reject(new Error(`Failed to load stylesheet: ${css}`)))
      appendChildToHead(link)
    })
  }

  return { createLinkElement, createScriptElement }
}
