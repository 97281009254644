export enum RouteName {
  INDEX = 'index',
  FILES_INDEX = 'files.index',

  HOME = 'home',
  MATTERS_INDEX = 'matters.index',
  MATTER_TASKS = 'matter-tasks',
  MATTER_TASK = 'matter-task',
  MATTER_MESSAGES = 'matter-messages',
  MATTER_INFORMATION = 'matter-information',
  MATTER_DOCUMENTS = 'matter-documents',
  MATTERS_INCOMPLETE = 'matters',
  MATTERS_COMPLETED = 'matters-completed',
  FORMS = 'forms',
  FORM = 'form',
  FORM_DRAFTS = 'drafts',
  MESSAGES = 'messages',
  MESSAGES_SEND = 'messages_send',
  MESSAGE = 'message',
  SETTINGS = 'settings',
  FAQ = 'faq',

  NOT_FOUND = 'not-found',
}
