import { buildCdnLinkElements } from '@/helpers/CdnLinkBuilder'
import getHeadElementManager from '@/helpers/HeadElementManager'

const { createLinkElement, createScriptElement } = getHeadElementManager()

export default async function loadEmbedPackages() {
  const { css, js } = buildCdnLinkElements('forms')

  await createLinkElement(css, 'forms-embed-css')
  await createScriptElement(js, 'forms-embed-js')
}
