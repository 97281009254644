<script lang="ts" setup>
import { AButton, ACard, List, type ListItemInterface } from '@atabix/vue-ui'
import { useI18n } from 'vue-i18n'

export interface ProxyLoginListProperties {
  disabled?: boolean
  proxyItems?: ListItemInterface[]
}

export interface ProxyLoginListEmits {
  (event: 'logout'): void
}

const { t } = useI18n({
  messages: {
    en: {
      logout: 'Logout',
    },
    nl: {
      logout: 'Uitloggen',
    },
    de: {
      logout: 'Abmelden',
    },
  },
})

defineProps<ProxyLoginListProperties>()

const emit = defineEmits<ProxyLoginListEmits>()

function onLogoutButtonClick() {
  emit('logout')
}
</script>

<template>
  <div class="proxy-login-list">
    <ACard :class="{ 'atabix-card--disabled': disabled }" :collapse="true">
      <List :collapsed="false" :items="proxyItems" />
    </ACard>

    <AButton
      class="proxy-login-list__button"
      data-test="button:logout"
      :title="t('cancel')"
      type="submit"
      variant="ghost"
      @click="onLogoutButtonClick"
    />
  </div>
</template>

<style lang="scss" scoped>
.proxy-login-list {
  max-height: 600px;
  overflow-y: auto;
  @apply grid;
  @apply gap-4;

  .proxy-login-list__button {
    @apply w-full;
  }
}

.atabix-card--disabled {
  @apply bg-background-disabled;
  @apply cursor-not-allowed;
}

:deep(.atabix-list) {
  @apply px-4;
  @apply gap-0;

  > div {
    @apply border-b;
    @apply border-border;

    &:last-child {
      @apply border-none;
    }
  }

  .atabix-list-item {
    @apply my-4;
  }

  .atabix-list-item__item-container {
    @apply w-full;
    @apply justify-between;
    @apply px-2;
  }
}
</style>
