import { Str } from '@atabix/core/src/Str'

import { ApplicationState } from '@/enums/ApplicationState'
import type { Settings } from '@/models/Setting'
import { authorizedHeaderResolver, baseHeaderResolver, environmentBaseUrlResolverFactory, FetchError } from '@/plugins/http'
import { useAppStore } from '@/stores'
import { useSettingsStore } from '@/stores/settings.ts'

export const Portal = {
  async teapot(versionId?: string): Promise<Partial<Settings>> {
    const settings = useSettingsStore()

    if (!versionId && settings.get('version')) {
      versionId = settings.get('version')
    }

    const url = environmentBaseUrlResolverFactory()() || ''
    const headers = new Headers(baseHeaderResolver() as Record<string, string>)

    if (versionId) headers.set('X-Portal-Version', versionId)

    const response = await fetch(`${Str.trim(url, '/')}/portal`, { headers })

    if (!response.ok) throw new FetchError(response.statusText, response.status, await response.json())

    return await response.json()
  },

  async settings(versionId?: string): Promise<Settings> {
    const url = environmentBaseUrlResolverFactory()() || ''
    const headers = new Headers(authorizedHeaderResolver() as Record<string, string>)

    if (versionId) headers.set('X-Portal-Version', versionId)

    const response = await fetch(`${Str.trim(url, '/')}/portal/settings`, { headers })

    const appStore = useAppStore()

    if (response.status === 422) {
      appStore.canUsePortal = false
    } else if (response.ok) {
      appStore.canUsePortal = true
    } else {
      throw new FetchError(response.statusText, response.status, await response.json())
    }

    return await response.json()
  },
}
