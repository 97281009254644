import { createRouter, createWebHistory, type Router } from 'vue-router'

import { RouteName } from '@/enums'
import { RouteMiddlewareFactory } from '@/RouteMiddlewareFactoryLocal'
import {
  AuthMiddleware,
  GuestMiddleware,
  InitializeAppMiddleware,
  InitializeSettingsMiddleware,
  ReadyMiddleware,
  VersionMiddleware,
} from '@/router/middleware'
import NotFound from '@/views/Errors/NotFound.vue'

export interface RouteMeta {
  middleware?: 'guest'
  activatesSideMenuItem?: RouteName
}

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: RouteName.HOME,
      meta: { middleware: 'guest' } satisfies RouteMeta,
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/matters',
      name: RouteName.MATTERS_INDEX,
      component: () => import('@/views/Matters/MattersView.vue'),
      children: [
        {
          path: '',
          name: RouteName.MATTERS_INCOMPLETE,
          component: () => import('@/views/Matters/Tabs/IncompleteTab.vue'),
        },
        {
          path: 'finished',
          name: RouteName.MATTERS_COMPLETED,
          component: () => import('@/views/Matters/Tabs/CompletedTab.vue'),
        },
      ],
    },
    {
      path: '/matters/:id',
      component: () => import('@/views/Matter/MatterView.vue'),
      meta: { activatesSideMenuItem: RouteName.MATTERS_INDEX } satisfies RouteMeta,
      children: [
        {
          path: '',
          name: RouteName.MATTER_TASKS,
          meta: { activatesSideMenuItem: RouteName.MATTERS_INDEX } satisfies RouteMeta,
          component: () => import('@/views/Matter/Tabs/TasksTab.vue'),
        },
        {
          path: 'task/:task',
          name: RouteName.MATTER_TASK,
          meta: { activatesSideMenuItem: RouteName.MATTERS_INDEX } satisfies RouteMeta,
          component: () => import('@/views/Matter/Tabs/TaskTab.vue'),
        },
        {
          path: 'messages',
          name: RouteName.MATTER_MESSAGES,
          meta: { activatesSideMenuItem: RouteName.MATTERS_INDEX } satisfies RouteMeta,
          component: () => import('@/views/Matter/Tabs/MessagesTab.vue'),
        },
        {
          path: 'information',
          name: RouteName.MATTER_INFORMATION,
          meta: { activatesSideMenuItem: RouteName.MATTERS_INDEX } satisfies RouteMeta,
          component: () => import('@/views/Matter/Tabs/InformationTab.vue'),
        },
        {
          path: 'documents',
          name: RouteName.MATTER_DOCUMENTS,
          meta: { activatesSideMenuItem: RouteName.MATTERS_INDEX } satisfies RouteMeta,
          component: () => import('@/views/Matter/Tabs/DocumentsTab.vue'),
        },
      ],
    },
    {
      path: '/forms',
      component: () => import('@/views/Forms/FormsView.vue'),
      children: [
        {
          path: '',
          name: RouteName.FORMS,
          component: () => import('@/views/Forms/Tabs/FormsTab.vue'),
        },
        {
          path: 'drafts',
          name: RouteName.FORM_DRAFTS,
          component: () => import('@/views/Forms/Tabs/DraftsTab.vue'),
        },
      ],
    },
    {
      path: '/forms/:id',
      name: RouteName.FORM,
      meta: { activatesSideMenuItem: RouteName.FORMS } satisfies RouteMeta,
      component: () => import('@/views/FormView.vue'),
    },
    {
      path: '/messages',
      component: () => import('@/views/Messages/MessagesView.vue'),
      children: [
        {
          path: '',
          name: RouteName.MESSAGES,
          component: () => import('@/views/Messages/Tabs/ReceivedTab.vue'),
        },
        {
          path: 'send',
          name: RouteName.MESSAGES_SEND,
          component: () => import('@/views/Messages/Tabs/SendTab.vue'),
        },
      ],
    },
    {
      path: '/faq',
      name: RouteName.FAQ,
      component: () => import('@/views/QuestionView.vue'),
    },
    {
      path: '/messages/:id',
      name: RouteName.MESSAGE,
      meta: { activatesSideMenuItem: RouteName.MESSAGES } satisfies RouteMeta,
      component: async () => await import('@/views/MessageView.vue'),
    },
    {
      path: '/settings',
      name: RouteName.SETTINGS,
      component: () => import('@/views/SettingsView.vue'),
    },
    {
      path: '/:pathMatch(.*)',
      name: RouteName.NOT_FOUND,
      component: NotFound,
      props: {
        code: 404,
        title: 'Page Not Found',
        description: 'The page you are looking for does not exist.',
        container: false,
      },
    },
  ],
})

router.beforeEach(
  RouteMiddlewareFactory({
    groups: {
      public: [InitializeAppMiddleware],
      private: [InitializeAppMiddleware, AuthMiddleware],
      guest: [InitializeAppMiddleware, GuestMiddleware],
      unknown: [],
    },
    after: [InitializeSettingsMiddleware, VersionMiddleware, ReadyMiddleware],
  }),
)

export default router
