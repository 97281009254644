import axios from 'axios'
import dayjs from 'dayjs'

import { LocaleUtilities } from '@/__TEMP__/LocaleUtilities'
import { type I18nLocale, setI18nLocale } from '@/plugins/i18n'
import { useSettingsStore } from '@/stores'

export class LocaleService {
  public static readonly FALLBACK_LOCALE = 'nl-NL'
  private static readonly LEGACY_LOCALES: Record<string, string> = {
    nl: 'nl-NL',
    en: 'en-GB',
    de: 'de-DE',
  }

  public static async setAppLocale(locale: string | undefined, fromStore?: boolean): Promise<boolean> {
    if (!LocaleUtilities.validateLocale(locale, true)) return false

    if (!fromStore) {
      useSettingsStore().setLocale(locale)
    }
    await LocaleService.onLocaleChange()
    return true
  }

  public static getLocale(): string {
    return useSettingsStore().locale ?? LocaleUtilities.getUrlParamLocale() ?? LocaleUtilities.getBrowserLocale() ?? LocaleService.FALLBACK_LOCALE
  }

  private static async onLocaleChange(): Promise<void> {
    const locale = LocaleService.getLocale()
    const language = LocaleUtilities.getLanguage(locale)

    LocaleUtilities.updateHtmlLocale(locale)
    axios.defaults.headers.common['Accept-Language'] = locale
    setI18nLocale(language as I18nLocale)
    dayjs.locale(language)
  }

  public static migrateLocale(locale: string): string {
    if (!LocaleUtilities.validateLocale(locale)) {
      // eslint-disable-next-line security/detect-object-injection
      return LocaleService.LEGACY_LOCALES[locale]
    }
    return locale
  }
}
