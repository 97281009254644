import type { NavigationGuardWithThis } from 'vue-router'

export type RouteMiddlewareGroup = 'unknown' | 'public' | 'private' | 'guest'
export type RouteMiddlewareGroups = Record<RouteMiddlewareGroup, NavigationGuardWithThis<undefined>[]>

// const useRouteMiddlewareLoopPreventionStore = defineStore('useRouteMiddlewareLoopPreventionStore', () => {
//   const timestamp = ref<number>(0)
//   const count = ref<number>(0)
//   return { timestamp, count }
// })

export const RouteMiddlewareFactory: (config: {
  groups: RouteMiddlewareGroups
  before?: NavigationGuardWithThis<undefined>
  after?: NavigationGuardWithThis<undefined> | NavigationGuardWithThis<undefined>[]
}) => NavigationGuardWithThis<undefined> = (config) => {
  return async (to, from, next) => {
    // const loopPreventionStore = useRouteMiddlewareLoopPreventionStore()
    // const newTimestamp = Math.ceil(Date.now() / 1000)
    // if (loopPreventionStore.timestamp !== newTimestamp) {
    //   loopPreventionStore.timestamp = newTimestamp
    //   loopPreventionStore.count = 0
    // }
    // loopPreventionStore.count += 1
    // if (loopPreventionStore.count > 10) {
    //   console.error('[RouteMiddlewareFactory] Loop detected, 10 redirects occurred within 1 second')
    //   throw new Error('[RouteMiddlewareFactory] Loop detected, 10 redirects occurred within 1 second')
    // }

    to.meta.middleware = to.meta.middleware || ['private']
    to.meta.middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const groups: RouteMiddlewareGroup[] = Array.isArray(to.meta.middleware) ? to.meta.middleware : ['private']
    const middlewares = groups.flatMap((group) => config.groups[`${group}`])
    if (config.before) {
      middlewares.unshift(config.before)
    }

    if (config.after) {
      config.after = Array.isArray(config.after) ? config.after : [config.after]
      middlewares.push(...config.after)
    }

    for (const middleware of middlewares) {
      const result = await middleware.call(undefined, to, from, next)

      // Navigation is canceled by force when a false has been returned from a middleware.
      if (result === false) return false
    }

    next()
  }
}
