// TODO: This file has been copied from vue-ui package and should be removed
// Portal is still using an old version, after it's updated this file should be removed and the one from package should be used.

export class LocaleUtilities {
  private static readonly LOCALE_REGEX = /^[a-z]{2}-[A-Z]{2}$/

  /**
   * This method updates the lang attribute on the html element.
   * NOTE: In your app you might need to update locales on more things such as axios and dayjs.
   *
   * @param locale The locale code to set, e.g. 'en-GB'
   */
  public static updateHtmlLocale(locale: string): void {
    const html = document.querySelector('html')
    if (html) {
      html.setAttribute('lang', LocaleUtilities.getLanguage(locale))
    }
  }

  /**
   * Validate the given locale code against the regex. (e.g. 'en-GB' is valid but 'en' is not)
   *
   * @param locale The locale code to validate.
   * @param log When true it will log an error message to the console if the locale is invalid.
   * @returns True when the locale is valid, false otherwise.
   */
  public static validateLocale(locale: string | undefined, log = false) {
    if (!locale) {
      return true
    }
    const valid = LocaleUtilities.LOCALE_REGEX.test(locale)
    if (log && !valid) {
      console.error(`Locale "${locale}" is not valid. Must be in the format en-GB`)
    }
    return valid
  }

  /**
   * Get the brower locale `navigator.language` or `navigator.userLanguage` (for old browsers).
   *
   * @returns The locale code set in the browser or undefined if not set. e.g. 'en-GB'
   */
  public static getBrowserLocale(): string | undefined {
    if (navigator.language) {
      return navigator.language ?? undefined
    }

    // NOTE: It's safe to ignore TS for this, it's a fallback for old browsers
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return navigator.userLanguage ?? undefined
  }

  /**
   * The the locale code from the `locale` URL parameter.
   * This can be useful for debugging purposes.
   *
   * @returns The locale code set in the URL parameters or undefined if not set.
   */
  public static getUrlParamLocale(): string | undefined {
    const urlParameters = new URLSearchParams(globalThis.location.search)
    const locale = urlParameters.get('locale')
    if (locale && LocaleUtilities.validateLocale(locale)) {
      return locale
    }
    return undefined
  }

  /**
   * Get the language code from the locale code.
   * For example 'en-GB' will return 'en'.`
   * NOTE: In most situations using locale codes is preferred but in certain libraries you might still need the language code.
   *
   * @param locale The locale code to get the language from
   * @returns The language code e.g. 'en'
   */
  public static getLanguage(locale: string): string {
    // TODO: Translations - this is tempory till we have fallback support on backend
    switch (locale) {
      case 'en-GB': {
        return 'en'
      }
      case 'de-DE': {
        return 'de'
      }
      default: {
        return 'nl'
      }
    }
    // return locale.split('-')[0]
  }
}
