import type { ALoginState, ListItemInterface } from '@atabix/vue-ui'
import { LoginIcon } from 'mdi-vue3'
import { computed, markRaw, nextTick, type Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthDriver } from '@/auth'
import { getProxies, type Proxy } from '@/auth/Proxies'
import { OAuth2TokenError } from '@/auth/TokenDriver'
import type { useAuthStore } from '@/stores/auth'

import type { CustomState } from './App.vue'
import { useAppStore } from './stores'
import { getTypesFromSettingsStore } from './utils/getTypesFromSettingsStore'

export interface UseProxiesOptions {
  auth: ReturnType<typeof useAuthStore>
  state: Ref<ALoginState>
  customState: Ref<CustomState | undefined>
  errorMessage: Ref<string | undefined>
  setLoading: (value?: boolean | undefined) => boolean
  setIsLoggingIn: (value?: boolean | undefined) => boolean
  isLoggingIn: Ref<boolean, boolean>
}

export function useProxies(options: UseProxiesOptions) {
  const { t } = useI18n({
    messages: {
      en: {
        'As myself': 'As myself',
      },
      nl: {
        'As myself': 'Als mijzelf',
      },
      de: {
        'As myself': 'Als ich selbst',
      },
    },
  })

  const { auth, state, customState, errorMessage, setLoading, setIsLoggingIn, isLoggingIn } = options

  const isLoadingProxies = ref(false)
  const proxies = ref<Proxy[] | undefined>(undefined)

  function getProxyMePlusProxiesAndFilterThoseByUsageType(proxies: Proxy[]): Proxy[] {
    const { types } = getTypesFromSettingsStore().value
    const usageType = types?.[0]

    const proxyMe: Proxy = {
      id: null,
      name: 'AS_MYSELF',
      type_id: auth.user?.type_id || '',
    }
    const newProxies = [proxyMe, ...proxies]

    return newProxies.filter(({ type_id }) => usageType === type_id)
  }

  async function fetchProxies(): Promise<void> {
    if (proxies.value !== undefined) return

    isLoadingProxies.value = true

    const newProxies = (await getProxies()) || []

    const finalNewProxies = getProxyMePlusProxiesAndFilterThoseByUsageType(newProxies)

    proxies.value = finalNewProxies

    isLoadingProxies.value = false
    if (!proxies.value?.length) {
      errorMessage.value = t('These credentials do not match our records.')
    }
  }

  function hasProxies(): boolean {
    return proxies.value !== undefined && proxies.value.length > 0
  }

  async function handleProxySelection(): Promise<void> {
    if (auth.hasUser && proxies.value) {
      auth.setIsSelectingProxy(true)
      state.value = 'custom'
      customState.value = 'proxy-login'

      if (!proxies.value?.length) {
        state.value = 'login'
        customState.value = undefined
        errorMessage.value = t('These credentials do not match our records.')

        return
      }

      if (proxies.value.length === 1) {
        await (proxies.value[0].id === null || proxies.value[0].id === 'null' ? onProxyNullLogin(proxies.value[0]) : onProxyLogin(proxies.value[0]))
      }
    }
  }

  async function onProxyLogin(proxy: Proxy): Promise<void> {
    if (proxy.id === auth.user?.id) {
      errorMessage.value = undefined
      auth.setIsSelectingProxy(false)
      state.value = 'login'
      return
    }

    setLoading(true)

    try {
      setIsLoggingIn(true)
      await useAuthDriver().proxyLogin(`${proxy.id}`)
      errorMessage.value = undefined
      auth.setIsSelectingProxy(false)
    } catch (error: unknown) {
      console.error(error)
      errorMessage.value = error instanceof OAuth2TokenError ? error.message : t('An unexpected error occurred')
    } finally {
      useAppStore().canUsePortal = true
      setIsLoggingIn(false)
      await new Promise<void>((resolve) => setTimeout(resolve, 1000))
      setLoading(false)
      state.value = 'login'
    }
  }

  async function onProxyNullLogin(proxy: Proxy): Promise<void> {
    setLoading(true)
    setIsLoggingIn(true)
    errorMessage.value = undefined
    auth.setIsSelectingProxy(false)
    useAppStore().canUsePortal = true
    setIsLoggingIn(false)
    await new Promise<void>((resolve) => setTimeout(resolve, 1000))
    setLoading(false)
    state.value = 'login'
  }

  function unsetProxies(): void {
    proxies.value = undefined
  }

  const proxyItems = computed((): ListItemInterface[] =>
    [...(proxies.value ?? [])].map(
      (proxy): ListItemInterface => ({
        id: `${proxy.id}`,
        title: proxy.name === 'AS_MYSELF' ? t('As myself') : proxy.name,
        icon: markRaw(LoginIcon),
        iconPosition: 'right',
        disabled: isLoggingIn.value,
        click: () => (proxy.id === null ? onProxyNullLogin(proxy) : onProxyLogin(proxy)),
      }),
    ),
  )

  return {
    isLoadingProxies,
    proxyItems: computed(() => proxyItems.value),
    fetchProxies,
    hasProxies,
    handleProxySelection,
    unsetProxies,
  }
}
