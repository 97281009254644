import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

import { Message } from '@/models'

export const useUnreadMessagesStore = defineStore('messages', () => {
  const itemLimit = 100
  const items = ref<Message[]>([])
  const count = computed((): number => items.value.length)
  const displayCount = computed((): string => (count.value <= itemLimit - 1 ? `${count.value}` : `${itemLimit - 1}+`))

  /** Remove the message from unread messages or fetch to make so there are no more items. */
  function readMessage(messageId: string): void {
    if (count.value === itemLimit) {
      refresh()
      return
    }
    items.value = items.value.filter((item) => item.id !== messageId) as Message[]
  }

  /** Refreshes the items by fetching them from the API. */
  async function refresh(): Promise<void> {
    try {
      const result = await new Message().limit(itemLimit).filter({ is_read: false }).all()
      items.value = result || []
    } catch (error) {
      console.error(error)
    }
  }

  return {
    items: readonly(items),
    count: readonly(count),
    displayCount: readonly(displayCount),
    readMessage,
    refresh,
  }
})
