<script setup lang="ts">
import type { ListItemInterface } from '@atabix/vue-ui'
import { AMenu, ASkeleton } from '@atabix/vue-ui'
import { useDark } from '@vueuse/core'
import { CogOutlineIcon, EmailOutlineIcon, FileDocumentOutlineIcon, HelpCircleOutlineIcon, HomeOutlineIcon, TextBoxEditOutlineIcon } from 'mdi-vue3'
import { computed, markRaw, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import ContainerWrapper from '@/components/ContainerWrapper.vue'
import PageHeader from '@/components/PageHeader.vue'
import { RouteName } from '@/enums'
import { PageEnum } from '@/enums/PageEnum'
import router, { type RouteMeta } from '@/router'
import loadEmbedPackages from '@/setup/loadEmbedPackages'
import { useAppStore } from '@/stores/app'
import { usePagesStore } from '@/stores/pages'
import { useUnreadMessagesStore } from '@/stores/unReadMessages'

const { t } = useI18n({
  messages: {
    en: {
      title: 'MyPortal',
    },
    nl: {
      title: 'MijnPortaal',
    },
    de: {
      title: 'MeinPortal',
    },
  },
})

const app = useAppStore()
const pages = usePagesStore()
const isDark = useDark()
const isLoading = ref<boolean>(true)
const unReadMessages = useUnreadMessagesStore()

const menu = computed<ListItemInterface[]>(() => {
  if (pages.items.length <= 0) {
    return []
  }

  return [
    {
      title: t(`pages.${RouteName.HOME}`),
      icon: markRaw(HomeOutlineIcon),
      to: { name: RouteName.HOME },
      visible: pages.has(PageEnum.DASHBOARD),
      dataTest: 'menu:home',
    },
    {
      active: (router.currentRoute.value.meta as RouteMeta | undefined)?.activatesSideMenuItem === RouteName.MATTERS_INDEX,
      title: t(`pages.${RouteName.MATTERS_INCOMPLETE}`),
      icon: markRaw(FileDocumentOutlineIcon),
      to: { name: RouteName.MATTERS_INCOMPLETE },
      visible: pages.has(PageEnum.CASES_BUSY) || pages.has(PageEnum.CASES_FINISHED),
      dataTest: 'menu:matters',
    },
    {
      active: (router.currentRoute.value.meta as RouteMeta | undefined)?.activatesSideMenuItem === RouteName.FORMS,
      title: t(`pages.${RouteName.FORMS}`),
      icon: markRaw(TextBoxEditOutlineIcon),
      to: { name: RouteName.FORMS },
      visible: pages.has(PageEnum.FORMS) || pages.has(PageEnum.FORMS_DRAFT_SUBMISSIONS),
      dataTest: 'menu:forms',
    },
    {
      active: (router.currentRoute.value.meta as RouteMeta | undefined)?.activatesSideMenuItem === RouteName.MESSAGES,
      title: t(`pages.${RouteName.MESSAGES}`),
      icon: markRaw(EmailOutlineIcon),
      to: { name: RouteName.MESSAGES },
      badge: unReadMessages.count > 0 ? unReadMessages.displayCount : undefined,
      visible: pages.has(PageEnum.MESSAGES),
      dataTest: 'menu:messages',
    },
    {
      title: t(`pages.${RouteName.FAQ}`),
      icon: markRaw(HelpCircleOutlineIcon),
      to: { name: RouteName.FAQ },
      visible: pages.has(PageEnum.FAQ),
      dataTest: 'menu:faq',
    },
    {
      title: t(`pages.${RouteName.SETTINGS}`),
      icon: markRaw(CogOutlineIcon),
      to: { name: RouteName.SETTINGS },
      visible: true,
      dataTest: 'menu:settings',
    },
  ]
})

async function initialize() {
  isLoading.value = true
  await loadEmbedPackages()
  unReadMessages.refresh()
  isLoading.value = false
}

onMounted(initialize)
</script>

<template>
  <PageHeader
    v-if="app.canUsePortal"
    data-test="authlayout:mobile"
    :items="menu"
    :logo="isDark ? app.setting('logo.dark') : app.setting('logo.light')"
    :title="app.title || t('title')"
  />
  <ContainerWrapper v-if="app.canUsePortal" class="py-9" data-test="authlayout">
    <div class="flex flex-row gap-8">
      <AMenu class="hidden min-w-[216px] md:block" :items="menu" />
      <div class="grow">
        <ASkeleton v-if="isLoading" class="h-96" />
        <RouterView v-else />
      </div>
    </div>
  </ContainerWrapper>
</template>
