import { getQueryParameter } from '@atabix/core'
import type { NavigationGuardWithThis } from 'vue-router'

import { ApplicationState } from '@/enums/ApplicationState'
import { FetchError } from '@/plugins/http'
import { useAppStore } from '@/stores/app'
import { useSettingsStore } from '@/stores/settings'

const InitializeAppMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const app = useAppStore()
  const settings = useSettingsStore()
  if (app.isInitialized || app.isReady) return true

  app.setState(ApplicationState.INITIALIZING)

  try {
    const versionId = getQueryParameter('version') || undefined

    await settings.teapot(versionId)

    app.setState(ApplicationState.INITIALIZED)
  } catch (error) {
    if (error instanceof FetchError) {
      if (error.status === 404) {
        await new Promise<void>((resolve) => setTimeout(resolve, 1000))
        app.setState(ApplicationState.UNKNOWN)
        return false
      }

      if (error.status === 503) {
        await new Promise<void>((resolve) => setTimeout(resolve, 1000))
        app.setState(ApplicationState.MAINTENANCE)
        return false
      }
    }

    app.setState(ApplicationState.FATAL)
  }
}

export default InitializeAppMiddleware
