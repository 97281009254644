function resolveAtabaseEnvironment(): 'dev' | 'test' | 'acceptance' | 'production' {
  const { hostname } = globalThis.location

  switch (true) {
    case hostname === 'localhost':
    case hostname.endsWith('.test'):
    case hostname.endsWith('.dev.atabase.nl'): {
      return 'dev'
    }
    case hostname.endsWith('.test.atabase.nl'): {
      return 'test'
    }
    case hostname.endsWith('.acceptance.atabase.nl'): {
      return 'acceptance'
    }
    default: {
      return 'production'
    }
  }
}

export function resolveCdn(): string {
  switch (resolveAtabaseEnvironment()) {
    case 'dev': {
      return 'https://cdn.dev.atabase.nl/'
    }
    case 'test': {
      return 'https://cdn.test.atabase.nl/'
    }
    case 'acceptance': {
      return 'https://cdn.acceptance.atabase.nl/'
    }
    default: {
      return 'https://cdn.atabase.nl/'
    }
  }
}

export const isLocal = import.meta.env.DEV
/* DTAP (OTAP); Denotes the common application environments. */
export const isDevelopment = import.meta.env.MODE === 'development'
export const isTest = import.meta.env.MODE === 'test'
export const isAcceptance = import.meta.env.MODE === 'acceptance'
export const isProduction = import.meta.env.MODE === 'production'
