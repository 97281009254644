<script lang="ts" setup>
import { AButton, ACheckboxInput, ALoginSuccessMessage, ATextInput } from '@atabix/vue-ui'
import { computed } from 'vue'
import { ref, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'

import type { SettingsRegistrationField } from '@/models/Setting'

type RegisterFormStatus = 'initial' | 'success' | 'error'

export type RegisterFormPayload = Record<string, string>

export interface RegisterFormProperties {
  loading?: boolean
  initialPayload: RegisterFormPayload
  fields: SettingsRegistrationField[]
  privacyPolicyUrl?: string
}

export type RegisterFormSubmitData = {
  payload: RegisterFormPayload
  setSuccess: () => void
  setError: () => void
}

export interface RegisterFormEmits {
  (event: 'submit', data: RegisterFormSubmitData): void
  (event: 'cancel'): void
}

defineOptions({ inheritAttrs: false })

const { t } = useI18n({
  messages: {
    en: {
      consent: 'I agree to the <a class="consent-url" href="{url}" rel="noopener noreferrer" target="_blank">privacy policy</a> and registration',
      request: 'Create account',
      back: 'Back to login',
      success: 'An email has been sent to verify your email address.',
    },
    nl: {
      consent: 'Ik ga akkoord met de <a class="consent-url" href="{url}" rel="noopener noreferrer" target="_blank">privacybeleid</a> en registratie',
      request: 'Account aanmaken',
      back: 'Terug naar inloggen',
      success: 'Er is een e-mail verzonden om uw e-mailadres te verifiëren.',
    },
    de: {
      consent:
        'Ich stimme der <a class="consent-url" href="{url}" rel="noopener noreferrer" target="_blank">Datenschutzerklärung</a> und Registrierung zu',
      request: 'Konto erstellen',
      back: 'Zurück zur Anmeldung',
      success: 'Eine E-Mail wurde gesendet, um Ihre E-Mail-Adresse zu überprüfen.',
    },
  },
})

const properties = withDefaults(defineProps<RegisterFormProperties>(), {
  loading: false,
  privacyPolicyUrl: undefined,
})

const emit = defineEmits<RegisterFormEmits>()
const payload = ref<RegisterFormPayload>(properties.initialPayload)
const status = ref<RegisterFormStatus>('initial')
const isConsentChecked = ref<boolean>(false)

const hasConsented = computed((): boolean => (properties.privacyPolicyUrl ? isConsentChecked.value : true))
const isFormFieldEmailValid = computed(
  (): boolean => !('email' in payload.value) || (!!payload.value.email && /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/.test(payload.value.email as string)),
)

const isFormFieldUsernameValid = computed(
  (): boolean =>
    'email' in payload.value || (!('email' in payload.value) && /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/.test(payload.value.username as string)),
)

const isFormFieldsRequiredValid = computed((): boolean =>
  Object.entries(payload.value).every(([id, value]) => {
    const field = properties.fields.find((field) => field.id === id)
    return field?.is_required ? !!value : true
  }),
)

const isFormValid = computed(
  (): boolean => hasConsented.value && isFormFieldEmailValid.value && isFormFieldUsernameValid.value && isFormFieldsRequiredValid.value,
)

const isConfirmDisabled = computed((): boolean => {
  return properties.loading || !isFormValid.value
})

function setSuccess(): void {
  status.value = 'success'
}

function setError(): void {
  status.value = 'error'
}

function onFormSubmit(): void {
  emit('submit', { payload: toRaw(payload.value), setError, setSuccess })
}
</script>

<template>
  <form class="register-form" @submit.prevent="onFormSubmit">
    <template v-if="status === 'initial' || status === 'error'">
      <template v-for="field in fields">
        <ATextInput
          v-if="!field.type || field.type === 'text' || field.type === 'email'"
          :id="field.id"
          :key="field.id"
          v-model="payload[field.id]"
          :disabled="loading"
          :label="field.name"
          :name="field.id"
          :placeholder="field.placeholder"
          :required="field.is_required"
          :type="field.type || 'text'"
        />
      </template>

      <ACheckboxInput v-if="privacyPolicyUrl" id="consent" v-model="isConsentChecked" :disabled="loading" label="" name="consent" required>
        <template #label>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="consent-text" v-html="t('consent', { url: privacyPolicyUrl })"></span>
        </template>
      </ACheckboxInput>

      <AButton class="register-form__button" :disabled="isConfirmDisabled" :loading="loading" :title="t('request')" type="submit" />
    </template>

    <template v-if="status === 'success'">
      <ALoginSuccessMessage :message="t('success')" />
    </template>

    <AButton
      class="register-form__button"
      :disabled="loading"
      :title="t('back')"
      :variant="status === 'success' ? 'outline' : 'ghost'"
      @click="emit('cancel')"
    />
  </form>
</template>

<style lang="scss">
.register-form {
  @apply grid;
  @apply gap-4;

  .register-form__button {
    @apply w-full;
  }

  .consent-text {
    a {
      @apply text-foreground-accent;
    }
  }
}
</style>
