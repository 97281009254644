<script setup lang="ts">
import { ALabel } from '@atabix/vue-ui'
import { openErrorDialog } from '@atabix/vue-ui/src/lib/dialog/utils'
import { type HTMLAttributes, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import ALanguageInput from '@/__TEMP__/ALanguageInput.vue'
import { LocaleService } from '@/helpers/LocaleService'
import { User } from '@/models'
import { useAuthStore } from '@/stores/auth'

const properties = defineProps<{
  label?: string
  ariaLabel?: string
  class?: HTMLAttributes['class']
}>()

const locale = ref<string>(LocaleService.getLocale())
const auth = useAuthStore()

const { t } = useI18n({
  messages: {
    en: {
      English: 'English',
      German: 'Deutsch',
      Dutch: 'Nederlands',
    },
    nl: {
      English: 'English',
      German: 'Deutsch',
      Dutch: 'Nederlands',
    },
    de: {
      English: 'English',
      German: 'Deutsch',
      Dutch: 'Nederlands',
    },
  },
})

async function updateLocale(locale: string) {
  if (!auth.user) {
    LocaleService.setAppLocale(locale)
    return
  }
  try {
    const user = await auth.user.update({ language: locale })
    auth.setUser(new User().hydrate({ ...auth.user, language: user.language }))
  } catch (error) {
    console.error(error)
    openErrorDialog({ title: t('unexpected error occurred'), description: t('error changing {item}', { item: t('language', 1) }) })
  }
}

// TODO: Translations - Get languages from backend
const languageItems = [
  { code: 'nl-NL', name: 'Nederlands' },
  { code: 'en-GB', name: 'Engels' },
  { code: 'de-DE', name: 'Duits' },
]

watch(() => locale.value, updateLocale)
</script>

<template>
  <div class="language-select">
    <ALabel v-if="label">{{ label }}</ALabel>
    <ALanguageInput v-model="locale" :aria-label :class="properties.class" :items="languageItems" />
  </div>
</template>

<style scoped>
.language-select {
  @apply flex flex-col gap-y-3;
}
</style>
