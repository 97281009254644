import { onBeforeUnmount, type Ref, ref } from 'vue'

interface VisibilityListenerOptions {
  /** Callback when the document becomes visible */
  onVisible?: () => void

  /** Callback when the document becomes hidden */
  onHidden?: () => void

  /** Whether to start listening immediately (default: true) */
  immediate?: boolean
}

interface VisibilityListenerReturn {
  /** Current visibility state - true if visible, false if hidden */
  isVisible: Ref<boolean>

  /** Start listening for visibility changes */
  start: () => void

  /** Stop listening for visibility changes */
  stop: () => void
}

/**
 * Composable for handling document visibility changes with manual start/stop control
 *
 * @example
 * const { isVisible, start, stop } = useDocumentVisibility({
 *   onVisible: () => console.log('Tab is now visible'),
 *   onHidden: () => console.log('Tab is now hidden')
 * })
 *
 * @param options Configuration options
 * @returns Visibility state and control methods
 */
export function useDocumentVisibility(options?: VisibilityListenerOptions): VisibilityListenerReturn {
  const { onVisible, onHidden, immediate = true } = options || {}
  const isVisible = ref<boolean>(!document.hidden)
  const isListening = ref<boolean>(false)

  function handleVisibilityChange(): void {
    isVisible.value = !document.hidden

    if (isVisible.value) {
      onVisible?.()
    } else {
      onHidden?.()
    }
  }

  function start(): void {
    if (isListening.value) return

    document.addEventListener('visibilitychange', handleVisibilityChange)
    isListening.value = true
  }

  function stop(): void {
    if (!isListening.value) return

    document.removeEventListener('visibilitychange', handleVisibilityChange)
    isListening.value = false
  }

  if (immediate) {
    start()
  }

  onBeforeUnmount(() => {
    stop()
  })

  return {
    isVisible,
    start,
    stop,
  }
}
