import { type Matter } from '@/models/Matter'
import { type Media } from '@/models/Media'
import { Crm } from '@/plugins/http'

import type { ModelMeta } from '.'

// #region Enums
// #endregion

// #region Interfaces

export type MessageAuthor = {
  name: string
  id: string
}

export type MessageTarget = ModelMeta

export type MessageDirection = 'in' | 'out'

export type MessageIncludes = 'matter' | 'data' | 'target_details' | 'media' | 'links'

export type MessageMetaIncludes = never

export type MessageSorts = never

export type MessageFilters = {
  search?: string
  target?: string
  entity?: string
  type?: 'applicant'
  is_read?: boolean
  direction?: MessageDirection
}

export type MessageCreatePayload = {
  target: MessageTarget
  body: string
  subject?: string
  media?: string[]
}

export type MessageUpdatePayload = {
  is_read?: boolean
}

export type MessagePutPayload = {
  never: never
}

// #endregion

// #region Constants
// #endregion

export class Message extends Crm<
  MessageFilters,
  MessageCreatePayload,
  MessageUpdatePayload,
  MessagePutPayload,
  MessageIncludes,
  MessageMetaIncludes,
  MessageSorts
> {
  // #region Class properties

  protected $endpoint = '/customers/messages'
  protected $primaryKey: keyof this = 'id'
  protected $fillable: Array<keyof this> = []

  // FIXME report.comment not a valid type in backend. check with them
  public $type = 'message'

  // #endregion

  // #region General attributes

  public id!: string
  public subject!: string
  public created_at!: string

  // #endregion

  // #region Default attributes

  public body!: string
  public author!: MessageAuthor
  public direction!: MessageDirection
  public is_read!: boolean
  // #endregion

  // #region Included attributes

  public matter?: Pick<Matter, 'id' | 'name' | 'is_accessible'>
  public media!: Media[]

  // #endregion

  // #region Relationship methods
  // #endregion

  // #region Endpoint methods
  // #endregion

  // #region Class methods
  // #endregion

  // #region Async methods (api calls only)
  // #endregion

  // #region Attribute getters & Setters
  // #endregion

  // #region Getters & Setters
  // #endregion
}
