import TokenDriver from '@/auth/TokenDriver'
import { LocaleService } from '@/helpers/LocaleService'
import { useTokenStore } from '@/stores'
import { getTypesFromSettingsStore } from '@/utils/getTypesFromSettingsStore'

import { useAuthDriver } from '.'

export interface Proxy {
  id: string | null
  name: string
  type_id: string
}

export async function getProxies(): Promise<Proxy[] | null> {
  async function getResponse(): Promise<Response> {
    const { types } = getTypesFromSettingsStore().value

    const typesParameters = types ? types.map((type) => `&types[]=${type}`).join('') : ''

    const url = `${TokenDriver.url('/customers/proxies')}?${typesParameters}`

    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': LocaleService.getLocale(),
        Authorization: useTokenStore().access ? `Bearer ${useTokenStore().access}` : '',
      }),
    })

    console.log('response', response)

    return response
  }

  let json: Proxy[] | null = null

  const response = await getResponse()

  if (response.status === 401) {
    const token = useTokenStore()

    if (token.refresh) {
      await TokenDriver.refresh(120)

      if (token.access) {
        const originalRequestResponse = await getResponse()

        if (originalRequestResponse) {
          json = await originalRequestResponse.json()
        }
      }

      if (!json) {
        await useAuthDriver().logout()

        return null
      }
    }
  }

  json = await response.json()

  return json
}
